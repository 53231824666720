<template>
  <div>
    <vx-card title="Inbound - Inter Warehouse">
      <vs-tabs value="1">
        <vs-tab @click="goPurchaseOrder" index="0" label="Purchase Order"></vs-tab>
        <vs-tab index="1" label="Inter Warehouse"></vs-tab>
        <vs-tab @click="goCustomerReturn" index="2" label="Customer Return"></vs-tab>
        <vs-tab @click="goPodReturn" index="3" label="POD Return"></vs-tab>
      </vs-tabs>
      <div class="mt-3 ml-1 vx-row">
        <vs-tabs>
          <vs-tab label="Receive">
            <tab-received></tab-received>
          </vs-tab>
          <vs-tab label="HU Conversion" disabled>
          </vs-tab>
          <vs-tab label="Put-away">
            <TabPutAway></TabPutAway>
          </vs-tab>
          <vs-tab label="Complete">
            <tab-completed></tab-completed>
          </vs-tab>
        </vs-tabs>
      </div>
    </vx-card>
  </div>
</template>
<script>
import TabReceived from "./components/TabReceived.vue";
import TabPutAway from "./components/TabPutAway.vue";
import TabCompleted from "./components/TabCompleted.vue";
export default {
  components: {
    TabReceived,
    TabPutAway,
    TabCompleted,
  },
  data() {
    return {
      activeTab: "Received",
      draw: 0,
      transactionDate: null,
      selectedSrcWarehouse: null
    }
  },
  methods: {
    handleFilter() {
      this.draw++;
    },
    goPurchaseOrder() {
      this.$router.push({
        name: this.$store.state.inbound.purchaseOrder.baseRouterName,
      });
    },
    goCustomerReturn() {
      this.$router.push({
        name: this.$store.state.inbound.customerReturn.baseRouterName,
      });
    },
    goPodReturn() {
      this.$router.push({
        name: this.$store.state.inbound.podReturn.baseRouterName,
      });
    },
  }
}
</script>
