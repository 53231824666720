<template>
    <div>
        <div class="mt-3 ml-1 vx-row">
            <div class="mb-6 vx-row" style="width:70%">
                <div class="flex items-center w-full vx-col sm:w-1/4">
                    <span>Source Warehouse Code</span>
                </div>
                <div class="w-full vx-col sm:w-3/4">
                    <CustomMSelect base-url="/api/wms/v1/master/warehouses" label="name" track-by="name" @selected="(val) => {
                        this.selectedSrcWarehouse = val
                    }"></CustomMSelect>
                </div>
            </div>
            <div class="mb-6 vx-row" style="width:70%">
                <div class="flex items-center w-full vx-col sm:w-1/4">
                    <span>Transaction Date</span>
                </div>
                <div class="w-full vx-col sm:w-3/4">
                    <RangePicker @selected="(val) => { selectedDate = val }"/>
                </div>
            </div>
            <div class="mb-6 vx-row" style="width:70%">
                <div class="flex items-center w-full vx-col sm:w-1/4">
                </div>
                <div class="w-full vx-col sm:w-3/4">
                    <vs-button color="primary" @click="handleFilter()">Filter</vs-button>
                </div>
            </div>
        </div>
        <div class="mt-3 vx-row">
            <div class="w-full vx-col mb-base">
                <Table :draw="draw" :transaction-date="date"
                    :status="activeTab" :ranged-date="selectedDate"
                    :source-warehouse-id="(this.selectedSrcWarehouse) ? this.selectedSrcWarehouse.id : 0">
                </Table>
            </div>
        </div>
    </div>
</template>
<script>
import CustomMSelect from "@/components/CustomMultiSelect.vue";
import Datepicker from "vuejs-datepicker";
import Table from "./TableCompleted.vue";
import RangePicker from "@/components/DateRangePicker.vue";
import moment from "moment";
export default {
    components: {
        CustomMSelect,
        Datepicker,
        RangePicker,
        Table
    },
    data() {
        return {
            activeTab: "OnCompleted",
            draw: 0,
            date: moment().startOf('month').toDate(),
            selectedSrcWarehouse: null,
            selectedDate: null,
        }
    },
    methods: {
        handleFilter() {
            this.draw++;
        },
    }
}
</script>
